<template>
  <Layout class="main-cols">
    <div
      class="container is-fluid lg:ml-0"
      v-if="$route.name === 'disposicion'"
    >
      <div
        v-if="getDisposicionDetalle != null"
        class="columns is-multiline flex-col md:flex-row"
      >
        <div class="column">
          <p class="title has-text-grey-darker text-left mb-0">
            {{ esArrendamiento ? "Arrendamiento" : "Disposición" }}
            {{ 
              getDisposicionDetalle.pasiva 
                ? "Pasiva" 
                : esArrendamiento 
                    ? "Activo"
                    : "Activa" 
            }} 
            #{{ idToShow(getDisposicionDetalle) }} {{ getDisposicionDetalle.pasiva ? ` | ${getDisposicionDetalle.identificador_fondeador ? getDisposicionDetalle.identificador_fondeador : ''}` : '' }}
            <span
              v-if="getDisposicionDetalle.credito_status.activo == 1"
              class="font-light has-text-grey-light"
            >
              con estatus
              <span
                class="font-bold"
                :class="{
                  'has-text-success':
                    getDisposicionDetalle.credito_status.id == 8 ||
                    getDisposicionDetalle.credito_status.id == 17
                }"
              >
                {{ getDisposicionDetalle.credito_status.nombre }}
              </span>
              {{ getDisposicionDetalle.acreditado ? `de ${getDisposicionDetalle.acreditado.nombre_completo.split('|')[0]}` : '' }}
            </span>
          </p>
          <!-- <p
            class="text-left has-text-primary underline font-medium text-lg"
            v-if="getDisposicionDetalle.folio_credito"
          >
            Folio #{{ getDisposicionDetalle.folio_credito }}
          </p> -->
        </div>
        <div
          class="column text-right is-narrow"
          v-if="
            status_detalle < 3 &&
              getDisposicionDetalle.credito_status.activo == 0 &&
              !esCreditoGrupal
          "
        >
          <b-button type="is-light" @click="editDisposicion">Editar</b-button>
        </div>
        <div
          class="column text-right is-narrow"
          v-if="status_detalle >= 1 && status_detalle < 4"
        >
          <b-button type="is-danger" @click="declinarDisposicion" outlined
            >Declinar
          </b-button>
        </div>
        <div class="column text-right is-narrow" v-if="status_detalle === 0">
          <b-button type="is-danger" @click="archivarDisposicion" outlined
            >Archivar
          </b-button>
        </div>
        <div class="column text-right is-narrow" v-if="status_detalle === 0">
          <b-button type="is-danger" @click="eliminarDisposicion" outlined
            >Eliminar
          </b-button>
        </div>
        <div
          class="column text-right is-narrow"
        >
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            class="text-left"
          >
            <b-button
              class="w-full is-light has-text-grey-darker"
              slot="trigger"
              slot-scope="{ active }"
              :outlined="true"
              :icon-right="active ? 'caret-up' : 'caret-down'"
            >
              Opciones
            </b-button>
            <b-dropdown-item
              v-if="getDisposicionDetalle.credito_status.activo == 1"
              class="cursor-pointer"
              @click="toggleModalTasa"
              aria-role="listitem"
            >
              Cambiar tasa interes ajeno
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="() => (is_modal_edo_cuenta = true)"
            >
              Imprimir Estado de cuenta
            </b-dropdown-item>
            <b-dropdown-item
              class="cursor-pointer"
              aria-role="listitem"
              @click="printTablaAmortizaciones"
            >
              Imprimir Tabla de amortizaciones
            </b-dropdown-item>
            <b-dropdown-item v-if="getDisposicionDetalle.credito_status.activo == 1" aria-role="listitem" has-link>
              <router-link
                :to="{
                  name: 'aplicarpago',
                  params: {
                    tipo: 'disposicion',
                    idObjeto: $route.params.idDisposicion
                  }
                }"
              >
                Aplicar Pago
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="getDisposicionDetalle.credito_status.activo == 1"
              class="cursor-pointer"
              style="word-wrap: break-word;"
              @click="() => (is_modal_refinanciamiento_vencido = true)"
              aria-role="listitem"
            >
              Configurar refinanciamiento vencido
            </b-dropdown-item>
            <b-dropdown-item
              v-if="getDisposicionDetalle.pasiva == 1 && getDisposicionDetalle.credito_status.activo == 1"
              class="cursor-pointer"
              style="word-wrap: break-word;"
              @click="() => (is_modal_asignar_activa = true)"
              aria-role="listitem"
            >
              Asignar disposición activa
            </b-dropdown-item>
            <b-dropdown-item
              class="cursor-pointer"
              style="word-wrap: break-word;"
              @click="() => (is_modal_referencia_bancaria = true)"
              aria-role="listitem"
            >
              Agregar referencia bancaria
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-12">
          <b-steps
            size="is-small"
            :type="
              getDisposicionDetalle.pasiva
                ? 'is-pasivo' 
                : esArrendamiento 
                  ? 'is-arrendamiento'
                  : 'is-primary'
            "
            :has-navigation="false"
            class="detalle-steps hidden md:block"
            v-model="status_detalle"
            @input="changeStatus"
            v-if="status_detalle !== null && statusDisabled"
          >
            <b-step-item
              label="Declinado"
              type="is-danger"
              :clickable="false"
            />
            <b-step-item
              class="separate"
              :clickable="false"
              label="Prospecto"
              value="1"
            />
            <b-step-item
              label="En Espera"
              :clickable="status_detalle == 1 ? true : false"
              value="2"
            />
            <b-step-item
              label="Autorizado"
              :clickable="status_detalle == 2 ? true : false"
            />
            <b-step-item
              v-if="getDisposicionDetalle.pasiva == 1"
              label="Recibido"
              :clickable="status_detalle == 3 ? true : false"
            />
            <b-step-item
              v-if="getDisposicionDetalle.pasiva == 0"
              label="Entregado"
              :clickable="status_detalle == 3 ? true : false"
            />
          </b-steps>
          <tabs
            :tabs="tabs"
            v-model="activeTab"
            :pasivo="getDisposicionDetalle.pasiva == 1"
            :arrendamiento="esArrendamiento"
          />
        </div>
        <div
          class="column animated is-12-mobile sm:order-3 md:order-none"
          v-show="activeTab == 0"
        >
          <Informacion :disposicion="getDisposicionDetalle" :arrendamiento="esArrendamiento" />
        </div>
        <div class="column" v-if="activeTab == 1">
          <div
            class="card-detalle-disp has-border-radius p-4 pt-0"
            v-if="getTablaAmortizacion != null"
          >
            <tabla-amortizacion
              :data="getTablaAmortizacion"
              :arrendamiento="esArrendamiento"
              table-class="table text-sm"
              title-class="font-bold has-text-grey-darker text-left text-2xl md:text-3xl"
              align="text-left"
              :tasa-base="
                getDisposicionDetalle.interes_ordinario.tasa_base.nombre
              "
            />
          </div>
        </div>
        <div class="column" v-if="activeTab == 2">
          <bitacora />
        </div>
        <div class="column" v-if="activeTab == 3">
          <documentacion />
        </div>
        <div class="column" v-if="activeTab == 4">
          <fondeos />
        </div>
        <div class="column" v-if="activeTab == 5">
          <detalle :disposicion="getDisposicionDetalle" />
        </div>
        <div class="column is-12" v-if="activeTab == 6">
          <castigos :disposicionId="getDisposicionDetalle.id" :pasivo="getDisposicionDetalle.pasiva == 1" />
        </div>
        <div class="column is-12" v-if="activeTab == 7">
          <pagos />
        </div>
        <div class="column" v-if="activeTab == 8">
          <supervisiones />
        </div>
        <div class="column" v-if="activeTab == 9 && !esArrendamiento">
          <garantias />
        </div>
        <div class="column" v-if="(activeTab == 10 && !esArrendamiento) || (esArrendamiento && activeTab == 9) ">
          <cuentas-c-p :disposicion="getDisposicionDetalle" />
        </div>
        <div class="column" v-if="(activeTab == 11 && !esArrendamiento) || (esArrendamiento && activeTab == 10)">
          <cuentas-financiadas
            :origen="origen.id"
            :origenIdToShow="origen.folio_credito || origen.id"
            :financiadas="getDisposicionDetalle.financiadas"
            :tipo-de-origen="origen.tipo"
            :esFinanciada="esFinanciada"
          />
        </div>
        <div
          id="formstatus"
          class="column new-item item is-12-mobile is-6-desktop sm:p-6 md:p-auto"
          v-if="getChangeStatus"
        >
          <manejador-status
            :status="getDisposicionDetalle.credito_status.nombre"
            :pasivo="getDisposicionDetalle.pasiva == 1 ? true : false"
            :disposicion_id="getDisposicionDetalle.id"
            :arrendamiento="esArrendamiento"
          />
        </div>
      </div>
    </div>
    <router-view v-else />
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_tasa_interes"
        :active.sync="is_modal_tasa_interes"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-tasa-interes-ajeno
          class="modal-has-overflow"
          :disposicion="getDisposicionDetalle"
        />
      </b-modal>
      <b-modal
        v-if="is_modal_edo_cuenta"
        :active.sync="is_modal_edo_cuenta"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-estado-cuenta
          class="modal-has-overflow"
          :disposicion-id="getDisposicionDetalle.id"
          :acreditado-id="getDisposicionDetalle.acreditado_id"
          :fecha-minima="getDisposicionDetalle.fecha_entrega"
        />
      </b-modal>
      <b-modal
        v-if="is_modal_refinanciamiento_vencido"
        :active.sync="is_modal_refinanciamiento_vencido"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-refinanciamiento-vencido
          class="modal-has-overflow"
          :disposicion="getDisposicionDetalle"
        />
      </b-modal>
      <b-modal
        v-if="is_modal_asignar_activa"
        :active.sync="is_modal_asignar_activa"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        @close="cargarDisposicionesFondeadas"
      >
        <modal-asignar-disposicion-activa class="modal-has-overflow" />
      </b-modal>
      <b-modal
        v-if="is_modal_referencia_bancaria"
        :active.sync="is_modal_referencia_bancaria"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-referencia-bancaria class="modal-has-overflow"/>
      </b-modal>
    </portal>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import TablaAmortizacion from "@/components/Disposicion/Detalle/TablaAmortizacion";
import Informacion from "@/components/Disposicion/Detalle/Informacion";
import Bitacora from "@/components/Disposicion/Detalle/Bitacora";
import Documentacion from "@/components/Disposicion/Detalle/Documentacion";
import Detalle from "@/components/Disposicion/Detalle/Detalle";
import Pagos from "@/components/Disposicion/Detalle/Pagos";
import Supervisiones from "@/components/Disposicion/Detalle/Supervisiones";
import Garantias from "@/components/Disposicion/Detalle/Garantias";
import Fondeos from "@/components/Disposicion/Detalle/Fondeos";
import Tabs from "@/components/generals/Tabs";
import ManejadorStatus from "@/components/Disposicion/Status/ManejadorStatus";
import ModalTasaInteresAjeno from "@/components/modals/ModalTasaInteresAjeno";
import ModalEstadoCuenta from "@/components/modals/ModalEstadoCuenta";
import ModalRefinanciamientoVencido from "@/components/modals/ModalRefinanciamientoVencido";
import ModalReferenciaBancaria from "@/components/modals/ModalReferenciaBancaria";
import ModalAsignarDisposicionActiva from "@/components/modals/ModalAsignarDisposicionActiva";
import CuentasCP from "@/components/Disposicion/Detalle/CuentasCP";
import Castigos from "@/components/Disposicion/Detalle/Castigos";
import CuentasFinanciadas from "@/views/cuentas/CuentasFinanciadas.vue";

export default {
  name: "detalleDisposicion",
  components: {
    TablaAmortizacion,
    Informacion,
    Bitacora,
    Documentacion,
    Detalle,
    Pagos,
    Supervisiones,
    Garantias,
    ManejadorStatus,
    Fondeos,
    Tabs,
    ModalTasaInteresAjeno,
    ModalEstadoCuenta,
    ModalRefinanciamientoVencido,
    ModalReferenciaBancaria,
    ModalAsignarDisposicionActiva,
    CuentasCP,
    CuentasFinanciadas,
    Castigos,
  },
  data() {
    return {
      activeTab: 0,
      status_detalle: null,
      is_modal_tasa_interes: false,
      is_modal_edo_cuenta: false,
      is_modal_refinanciamiento_vencido: false,
      is_modal_asignar_activa: false,
      is_modal_referencia_bancaria: false,
    };
  },
  watch: {
    activeTab: function (curr, prev) {
      switch (parseInt(curr)) {
        case 0:
          if (prev != 0) {
            this.$store.dispatch('disposicion/getDisposicionDetalle', this.$route.params.idDisposicion);
          }
          break;
        case 1:
          this.$store.dispatch(
            "disposicion/getTablaAmortizacion",
            this.$route.params.idDisposicion
          );
          break;
        default:
          break;
      }
    },  
    status_id: function(val) {
      this.setStatusDetalle(val);
    },
    $route: {
      handler: function() {
        if (
          (this.getDisposicionDetalle &&
            this.getDisposicionDetalle.id !==
              this.$route.params.idDisposicion)
        ) {
          this.$store.dispatch("disposicion/resetState");
          this.$store.dispatch("disposicion/changeStatus", false);
          this.$store
            .dispatch(
              "disposicion/getDisposicionDetalle",
              this.$route.params.idDisposicion
            )
            .then(data => {
              if (!data.response) {
                this.setStatusDetalle(data.credito_status.id);
                if (data.credito_status.activo == 1) {
                  this.activeTab = 5;
                }
              }
            });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //Mostrar / Ocultar modal de cambiar tasa de interes ajeno
    toggleModalTasa() {
      this.is_modal_tasa_interes = true;
      this.$store.dispatch("catalogos/getTasasBase");
    },
    /*
      Proceso para cambiar status, el dispatch es el que despues es leido por manejador status que 
      es el encargado de mostrar la card del status siguiente y sus peticiones a API
     */
    changeStatus() {
      this.$store.dispatch("disposicion/changeStatus", true);
      this.setStatusDetalle(this.getDisposicionDetalle.credito_status.id);
      if (this.$screen.width < 768)
        setTimeout(() => {
          document
            .getElementById("formstatus")
            .scrollIntoView({ block: "end", behavior: "smooth" });
        }, 1000);
    },
    //Función que lleva a la ruta para editar la disposición
    async editDisposicion() {
      if (this.esArrendamiento && this.getDisposicionDetalle.garantias.length > 0) {
        localStorage.removeItem("pasos");
        await this.$store.dispatch("garantias/setGarantiaParaArrendar", this.getDisposicionDetalle.garantias[0]);
      }
      this.$router.push({
        name: "editar_disposicion",
        params: { idEditarDisposicion: this.$route.params.idDisposicion }
      });
    },
    // Pasa la disposición al status de declinado
    declinarDisposicion() {
      this.$buefy.dialog.prompt({
        title: "Declinar disposición",
        message:
          "¿Estas seguro que deseas <b>declinar</b> esta disposición? <br /> ¿Cual es el motivo?",
        inputAttrs: {
          placeholder: "Motivo de declinado...",
          maxlength: 255
        },
        confirmText: "Declinar disposición",
        cancelText: "Cancelar",
        type: "is-danger",
        onConfirm: motivo =>
          this.$store.dispatch("disposicion/declinar", {
            id: this.$route.params.idDisposicion,
            motivo: motivo
          })
      });
    },
    // Pasa la disposición al status de archivado
    archivarDisposicion() {
      this.$buefy.dialog.prompt({
        title: "Archivar disposición",
        message:
          "Estás a punto de <b>Archivar</b> esta disposición. <br /> ¿Cual es el motivo?",
        inputAttrs: {
          placeholder: "Motivo de archivado...",
          maxlength: 255
        },
        confirmText: "Archivar",
        cancelText: "Cancelar",
        type: "is-danger",
        onConfirm: motivo =>
          this.$store.dispatch("disposicion/archivar", {
            id: this.$route.params.idDisposicion,
            motivo: motivo
          })
      });
    },
    eliminarDisposicion() {
      this.$buefy.dialog.confirm({
        title: "Eliminar disposición",
        message:
          "Estás a punto de <b>eliminar</b> esta disposición. <br /> ¿Estas seguro?",
        confirmText: "Eliminar",
        cancelText: "Cancelar",
        type: "is-danger",
        onConfirm: () =>
          this.$store.dispatch("disposicion/eliminar", 
            {
              id: this.$route.params.idDisposicion,
            }
          ).then(() => this.$router.replace("/disposiciones"))
      });
    },
    printTablaAmortizaciones() {
      this.$store.dispatch("disposicion/printTablaAmortizaciones", {
        disposicion_id: this.$route.params.idDisposicion
      });
    },
    //Sirve para mostrar en que paso del Step va
    setStatusDetalle(status_id) {
      switch (status_id) {
        case 2:
          this.status_detalle = 1;
          break;
        case 3:
          this.status_detalle = 2;
          break;
        case 4:
          this.status_detalle = 3;
          break;
        case 8:
          this.status_detalle = 4;
          break;
        case 6:
          this.status_detalle = 0;
          break;
        case 5:
          this.status_detalle = 5;
          break;
        default:
          this.status_detalle = null;
      }
    },
    cargarDisposicionesFondeadas() {
      const action = this.getDisposicionDetalle.pasiva == 0 ? 'Pasivas' : 'Activas'; 
      this.$store.dispatch(
        `disposicion/get${action}`,
        this.$route.params.idDisposicion
      );
    }
  },
  computed: {
    ...mapGetters("disposicion", [
      "getDisposicionDetalle",
      "getTablaAmortizacion",
      "getChangeStatus"
    ]),
    esFinanciada() {
      if (
        this.getDisposicionDetalle.origen_credito_core ||
        this.getDisposicionDetalle.origen_linea_credito ||
        this.getDisposicionDetalle.origen_garantia
      ) {
        return true;
      }

      return false;
    },
    esArrendamiento() {
      return this.getDisposicionDetalle ? this.getDisposicionDetalle?.producto_financiero?.tipo_credito.tipo.includes("Arrendamiento") : false;
    },
    esCreditoGrupal() {
      return this.getDisposicionDetalle?.linea_credito[0]?.grupo_credito_id;
    },
    origen() {
      let origen = null;
      if (this.getDisposicionDetalle.origen_credito_core) {
        origen = this.getDisposicionDetalle.origen_credito_core;
        origen.tipo = "Disposición";
        return origen;
      } else if (this.getDisposicionDetalle.origen_linea_credito) {
        origen = this.getDisposicionDetalle.origen_linea_credito;
        origen.tipo = "Línea";
        return origen;
      }

      origen = this.getDisposicionDetalle;
      origen.tipo = "Disposición";
      return origen;
    },
    status_id() {
      if (this.getDisposicionDetalle) {
        return this.getDisposicionDetalle.credito_status.id;
      }
      return "";
    },
    statusDisabled() {
      if (this.status_id == 5) {
        return false;
      }
      if (!this.getDisposicionDetalle.credito_status.activo) {
        return true;
      }
      return false;
    },
    tabs() {
      return this.esArrendamiento 
        ? [
            {
              label: "Información general"
            },
            {
              label: "Tabla de amortización"
            },
            {
              label: "Bitácora"
            },
            {
              label: "Documentación"
            },
            {
              label: "Fondeos",
              disabled:
                [18, 16, 2].includes(this.status_id) ||
                this.getDisposicionDetalle.pasiva == 1
            },
            {
              label: "Detalle",
              disabled: this.statusDisabled
            },
            {
              label: "Castigos",
              disabled: this.statusDisabled
            },
            {
              label: "Pagos",
              disabled: this.statusDisabled
            },
            {
              label: "Supervisiones",
              disabled: this.statusDisabled
            },
            {
              label: "Cuentas",
              disabled: this.statusDisabled
            },
            {
              label: "Cuentas financiadas",
              disabled: this.statusDisabled
            }
          ]
        : [
            {
              label: "Información general"
            },
            {
              label: "Tabla de amortización"
            },
            {
              label: "Bitácora"
            },
            {
              label: "Documentación"
            },
            {
              label: "Fondeos",
              disabled:
                [18, 16, 2].includes(this.status_id)
            },
            {
              label: "Detalle",
              disabled: this.statusDisabled
            },
            {
              label: "Castigos",
              disabled: this.statusDisabled
            },
            {
              label: "Pagos",
              disabled: this.statusDisabled
            },
            {
              label: "Supervisiones",
              disabled: this.statusDisabled
            },
            {
              label: "Garantias",
              disabled: this.statusDisabled
            },
            {
              label: "Cuentas",
              disabled: this.statusDisabled
            },
            {
              label: "Cuentas financiadas",
              disabled: this.statusDisabled
            }
          ]
    }
  },
  async created() {
    await this.$store.dispatch("disposicion/changeStatus", false);
    await this.$store
      .dispatch(
        "disposicion/getDisposicionDetalle",
        this.$route.params.idDisposicion
      )
      .then(data => {
        if (!data.response) {
          this.setStatusDetalle(data.credito_status.id);
          if (data.credito_status.activo == 1) {
            this.activeTab = 5;
          }
        }
      }); 
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.detalle-steps .steps .step-items .step-item:nth-child(2)::before {
  content: unset !important;
}
.detalle-steps .steps .step-items .step-item {
  .step-marker {
    background: var(--grey-lighter);
  }
  &:first-child {
    &.is-active {
      .step-marker {
        background-color: $danger !important;
      }
    }
    .step-marker {
      background: var(--grey-lighter) !important;
    }
  }
}
.is-pasivo .tabs.is-toggle li.is-active a {
  background-color: $pasivo;
  border-color: $pasivo;
}
.new-item {
  -webkit-animation: flexGrow 1s ease forwards;
  -o-animation: flexGrow 1s ease forwards;
  animation: flexGrow 1s ease forwards;
  min-width: 10px;
}
.remove-item {
  min-width: 10px;
  -webkit-animation: flexShrink 1s ease forwards;
  -o-animation: flexShrink 1s ease forwards;
  animation: flexShrink 1s ease forwards;
}
</style>
