<template>
  <div :class="align">
    <label class="font-bold has-text-primary" :class="titleClass">
      Tabla de amortización
    </label>
    <div class="flex justify-between columns">
      <tabs
        class="max-w-sm column is-4"
        :tabs="tabs"
        :arrendamiento="arrendamiento"
        @change="formatAmortizaciones"
        v-model="activeTab"
        :pasivo="getDisposicionDetalle && getDisposicionDetalle.pasiva == 1"
      />
      <div
        v-if="activeTab == 1"
        class="flex justify-around max-w-sm column is-2 btn-group pull-right"
      >
        <b-button @click="imprimirTabla" :type="arrendamiento ? 'is-arrendamiento' : 'is-primary'"
          ><i class="fa fa-print" aria-hidden="true"></i
        ></b-button>
        <b-button
          @click="tablaSinDetalles = !tablaSinDetalles"
          :type="tablaSinDetalles 
                  ? arrendamiento 
                    ? 'is-arrendamiento'
                    : 'is-primary' 
                  : 'is-ghost'"
          :icon-right="tablaSinDetalles ? 'expand' : 'compress'"
        />
        <b-button
          @click="tablaSoloSaldos = !tablaSoloSaldos"
          :type="tablaSoloSaldos 
                  ? arrendamiento 
                    ? 'is-arrendamiento'
                    : 'is-primary' 
                  : 'is-ghost'"
          ><i class="fas fa-dollar-sign" aria-hidden="true"></i
        ></b-button>
      </div>
    </div>
    <div v-if="activeTab == 1">
      <tabla-simulacion
        v-if="!tablaSinDetalles"
        :tablaAmortizacion="tablaAmortizacion"
        :tasaBase="tasaBase"
        :tableClass="tableClass"
        :sinIvaCapitalizado="sinIvaCapitalizado"
        :arrendamiento="arrendamiento"
        :soloSaldos="tablaSoloSaldos"
        :capital="data[0].capitalInsoluto"
      />
      <tabla-simulacion-sin-detalles
        v-if="tablaSinDetalles"
        :tablaAmortizacion="tablaAmortizacionSinDetalles"
        :tableClass="tableClass"
        :arrendamiento="arrendamiento"
        :soloSaldos="tablaSoloSaldos"
        :capital="data[0].capitalInsoluto"
      />
    </div>

    <div v-if="activeTab == 0">
      <tabla-amortizaciones
        :amortizaciones="
          getDisposicionDetalle &&
            getDisposicionDetalle.fechas_vencimiento_capital
        "
        :esUnaSolaAmortizacion="getDisposicionDetalle.tipo_pago.clave == 'vencimiento'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tabs from "@/components/generals/Tabs";
import TablaAmortizaciones from "./TablaAmortizaciones";
import TablaSimulacion from "./TablaSimulacion";
import TablaSimulacionSinDetalles from "./TablaSimulacionSinDetalles";

export default {
  name: "TablaAmortizacion",
  components: {
    Tabs,
    TablaAmortizaciones,
    TablaSimulacion,
    TablaSimulacionSinDetalles
  },
  data() {
    return {
      activeTab: 1,
      tablaSinDetalles: false,
      tablaSoloSaldos: false
    };
  },
  methods: {
    imprimirTabla() {
      if (this.$route.params.idDisposicion) {
        this.$store.dispatch("disposicion/printTablaAmortizaciones", {
          disposicion_id: this.$route.params.idDisposicion,
          sinDetalles: this.tablaSinDetalles,
          soloSaldos: this.tablaSoloSaldos
        });
      } else {
        this.datosParaImprimir.sinDetalles = this.tablaSinDetalles;
        this.datosParaImprimir.soloSaldos = this.tablaSoloSaldos;
        this.$store.dispatch(
          "disposicion/printSimulacion",
          this.datosParaImprimir,
        );
      }
    },
    formatAmortizaciones(tab) {
      switch (parseInt(tab)) {
        case 0:
          this.getDisposicionDetalle.fechas_vencimiento_capital.map(
            amortizacion => {
              amortizacion.pagada =
                amortizacion.pagada == 1 || amortizacion.pagada == "Pagada" ? "Pagada" : "Pendiente de pago";
              amortizacion.capital = this.$options.filters.currency(
                amortizacion.capital
              );
            }
          );
          break;
      }
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getDisposicionDetalle"]),
    tabs() {
      return [
        {
          label: "Amortizaciones",
          disabled:
            this.getDisposicionDetalle == null ||
            this.getDisposicionDetalle.credito_status.nombre == "Prospecto" ||
            ['editar_disposicion', 'financiar_cuenta'].includes(this.$route.name)
        },
        {
          label: "Simulación"
        }
      ];
    },
    tablaAmortizacion() {
      return this.tablaSoloSaldos
        ? this.data.filter(amortizacion => amortizacion.pagoTotal > 0)
        : this.data;
    },
    tablaAmortizacionSinDetalles() {
      const tablaSinDetalles = JSON.parse(JSON.stringify(this.data));

      return this.tablaSoloSaldos
        ? tablaSinDetalles.filter(amortizacion => amortizacion.pagoTotal > 0)
        : tablaSinDetalles;
    }
  },
  props: {
    data: Array,
    titleClass: String,
    tableClass: String,
    tasaBase: String,
    align: String,
    datosParaImprimir: Object,
    sinIvaCapitalizado: Boolean,
    arrendamiento: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
// .b-table.has-sticky-header {
//   height: auto !important;
//   max-height: 450px;
//   @media screen and (max-width: 768px) {
//     max-height: unset;
//   }
// }
// .tabla-amortizacion .has-sticky-header .table .is-subheading th {
//   top: 41px;
// }
// .table-invisible {
//   background-color: var(--white);
//   > div {
//     color: var(--white);
//   }
// }
</style>
