<template>
  <div v-if="getPagos != null && getTablaAmortizacion != null">
    <b-button
        icon-left="plus"
        type="is-primary"
        class="mt-3 font-regular float-right"
        tag="router-link"
        :to="{
          name: 'aplicarpago',
          params: {
            tipo: 'disposicion',
            idObjeto: $route.params.idDisposicion
          }
        }"
      >
        Aplicar nuevo pago
      </b-button>
    <x-y-chart :datos="datos" @filterpagos="filterPagos" />
    <div class="columns is-multiline">
      <div class="column is-12">
        <p class="title text-left mt-4">
          Pagos realizados
        </p>
        <b-table
          v-if="pagos_filtered.length > 0"
          :data="pagos_filtered"
          class="text-sm cursor-pointer"
          sticky-header
          :mobile-cards="false"
          focusable
        >
          <b-table-column
            field="id"
            subheading="Folio Pago"
            custom-key="id"
            meta-label="Folio"
            sortable
            v-slot="props"
          >
            <span class="has-text-info underline">
              <preview :id="props.row.id" tipo="pago">{{
                props.row.id
              }}</preview>
            </span>
          </b-table-column>
          <b-table-column
            field="fecha_contable.date"
            subheading="Fecha"
            custom-key="fecha_contable"
            sortable
            header-class="px-10"
            v-slot="props"
          >
            {{ props.row.fecha_contable.date | moment("DD-MM-YYYY") }}
          </b-table-column>
          <b-table-column
            field="instrumento_monetario.nombre"
            subheading="Instrumento Monetario"
            custom-key="instrumento_monetario"
            sortable
            v-slot="props"
          >
            {{ props.row.instrumento_monetario.nombre }}
          </b-table-column>
          <b-table-column
            field="cantidad_pagada"
            subheading="Monto recibido"
            class="text-right"
            custom-key="cantidad_pagada"
            sortable
            v-slot="props"
          >
            {{ props.row.cantidad_pagada | currency | hp }}
          </b-table-column>
          <b-table-column
            field="moneda.nombre"
            subheading="Moneda"
            custom-key="moneda"
            header-class="px-16"
            sortable
            v-slot="props"
          >
            {{ props.row.moneda.nombre }}
          </b-table-column>
          <!-- CAPITAL -->
          <b-table-column
            label="Capital"
            class="text-right"
            field="desglose_pagos.capital_vigente"
            custom-key="capital_vigente"
            cell-class="border-l-2"
            header-class="border-l-2"
            subheading="Vigente"
            sortable
            v-slot="props"
          >
            {{ props.row.desglose_pagos.capital_vigente | currency | hp }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Impago"
            field="desglose_pagos.capital_vencido_por_traspasar"
            custom-key="capital_impago"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.capital_vencido_por_traspasar
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido exigible"
            field="desglose_pagos.capital_vencido_exigible"
            custom-key="capital_vencido_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.capital_vencido_exigible | currency | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido no exigible"
            field="desglose_pagos.capital_vencido_no_exigible"
            custom-key="capital_vencido_no_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.capital_vencido_no_exigible
                | currency
                | hp
            }}
          </b-table-column>
          <!-- INTERES ORDINARIO -->
          <b-table-column
            label="Interes ordinario"
            class="text-right"
            field="desglose_pagos.interes_ordinario_vigente"
            custom-key="intord_vigente"
            cell-class="border-l-2"
            header-class="border-l-2"
            subheading="Vigente"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_ordinario_vigente | currency | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Impago"
            field="desglose_pagos.interes_ordinario_vencido_por_traspasar"
            custom-key="intord_impago"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_ordinario_vencido_por_traspasar
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido exigible"
            field="desglose_pagos.interes_ordinario_vencido_exigible"
            custom-key="intord_vencido_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_ordinario_vencido_exigible
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido no exigible"
            field="desglose_pagos.interes_ordinario_vencido_no_exigible"
            custom-key="intord_vencido_no_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_ordinario_vencido_no_exigible
                | currency
                | hp
            }}
          </b-table-column>
          <!-- INTERES REFINANCIADO -->
          <b-table-column
            label="Interes refinanciado"
            class="text-right"
            field="desglose_pagos.interes_refinanciado_vigente"
            custom-key="intref_vigente"
            cell-class="border-l-2"
            header-class="border-l-2"
            subheading="Vigente"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_refinanciado_vigente
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Impago"
            field="desglose_pagos.interes_refinanciado_vencido_por_traspasar"
            custom-key="intref_impago"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos
                .interes_refinanciado_vencido_por_traspasar
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido exigible"
            field="desglose_pagos.interes_refinanciado_vencido_exigible"
            custom-key="intref_vencido_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_refinanciado_vencido_exigible
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column
            class="text-right"
            subheading="Vencido no exigible"
            field="desglose_pagos.interes_refinanciado_vencido_no_exigible"
            custom-key="intref_vencido_no_exigible"
            sortable
            v-slot="props"
          >
            {{
              props.row.desglose_pagos.interes_refinanciado_vencido_no_exigible
                | currency
                | hp
            }}
          </b-table-column>
          <b-table-column v-slot="props">
            <b-button
                v-if="props.row.cancelable"
                class="bg-transparent text-red-500 border-0"
                @click="abrirModalEliminarPago(props.row.id, props.row.personas_id)"
                icon-right="trash" />
          </b-table-column>
        </b-table>
      </div>
      <div v-if="pagos_filtered.length == 0" class="column is-12">
        <div class="h-65vh">
          <empty-state
            type="svg"
            :vertical="true"
            imagen="/images/movimientos.svg"
            imgclass="w-2/5 mb-4"
            titulo="No hay pagos realizados"
            subtitulo="Intenta introduciendo otra fecha."
          />
        </div>
      </div>
    </div>
     <portal to="modalContainer">
        <b-modal
        v-if="is_modal_eliminar_pago"
        :active.sync="is_modal_eliminar_pago"
        :width="480"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-eliminar-pago
          class="modal-has-overflow"
          :pagoId="pago_id"
          :personaId="persona_id"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import XYChart from "@/components/charts/XYChart";
import EmptyState from "@/components/generals/EmptyState";
import ModalEliminarPago from "@/components/modals/ModalEliminarPago";
import { mapGetters } from "vuex";

export default {
  name: "Pagos",
  components: {
    EmptyState,
    XYChart,
    ModalEliminarPago
  },
  data() {
    return {
      selected: null,
      pagos_filtered: [],
      is_modal_eliminar_pago: false,
      pago_id: null,
      persona_id: null,
    };
  },
  watch: {
    selected: function(val) {
      this.$router.push({ name: "detallepago", params: { idPago: val.id } });
    },
    getPagos: function(newValue){
      this.pagos_filtered = this.pagos_filtered.filter(pago => newValue.includes(pago));
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getPagos", "getTablaAmortizacion"]),
    /*
      función encargada de obtener los pagos actuales asi como la tabla de amortización para ser pasado
      a la grafica
    */
    datos() {
      if (this.getPagos != null && this.getTablaAmortizacion != null) {
        let pagos_sorted = [ ...this.getPagos.desglose_pagos ];
        pagos_sorted = pagos_sorted.sort(
          (a, b) =>
            new Date(a.fecha_contable.date) - new Date(b.fecha_contable.date)
        );
        let amortizacion_sorted = this.getTablaAmortizacion;
        amortizacion_sorted = amortizacion_sorted.sort(
          (a, b) => new Date(a.fechaDePago) - new Date(b.fechaDePago)
        );
        if (pagos_sorted.length == 0 || amortizacion_sorted.length == 0) {
          return [];
        }
        return [
          {
            pagos_realizados: pagos_sorted,
            x_key: "fecha_contable.date",
            y_key: "cantidad_pagada"
          },
          {
            compromiso_de_pagos: amortizacion_sorted,
            x_key: "fechaDePago",
            y_key: "pagoTotal"
          }
        ];
      }
      return [];
    }
  },
  methods: {
    /*
      Filtrar los pagos un rango de fechas
      @fechas(Array[Date,Date]): rango de fecha
    */
    filterPagos(fechas) {
      this.pagos_filtered = this.getPagos.desglose_pagos.filter(
        pago =>
          this.$moment(pago.fecha_contable.date).toDate() > fechas[0] &&
          this.$moment(pago.fecha_contable.date).toDate() < fechas[1]
      );
    },
    abrirModalEliminarPago(pago_id, persona_id){
      this.is_modal_eliminar_pago = true;
      this.pago_id = pago_id;
      this.persona_id = persona_id;
      this.$store.dispatch("personas/getDetallesPersona", persona_id);
    }
  },
  created() {
    this.$store.dispatch(
      "disposicion/getPagos",
      this.$route.params.idDisposicion
    );
    this.$store.dispatch(
      "disposicion/getTablaAmortizacion",
      this.$route.params.idDisposicion
    );
  }
};
</script>
