<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0 has-background-grey-lighter">
        <p class="font-bold text-2xl">Fondear disposición</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div>
          <valid-select
            rules="required"
            label="Fuente de fondeo"
            placeholder="Seleccionar..."
            v-model="form.fuente_fondeo"
            :data="getFuentesFondeo"
            show="alias"
            realValue="id"
            :expanded="true"
            @input="getLineas"
          />
        </div>
        <div class="mt-3">
          <valid-input
            rules="required"
            label="ID fondeador"
            placeholder="Introduzca una descripción..."
            v-model="form.identificador_fondeador"
            type="text"
            :expanded="true"
          />
          <ValidAutoComplete
            rules="required"
            label="Línea de crédito"
            placeholder="Buscar..."
            v-model="form.linea_id"
            :data="getLineasCliente"
            fieldClass="mt-4"
            show="id"
            :expanded="true"
            v-if="getLineasCliente != null"
            :append-to-body="false"
            :custom="true"
          >
            <template v-slot:custom="slotProps">
              <div>
                <p
                  :class="{
                    'has-text-primary-darker':
                      slotProps.option.option.pasiva == 0,
                    'has-text-pasivo': slotProps.option.option.pasiva == 1
                  }"
                >
                  <b>Línea #{{ slotProps.option.option.id }}</b> -
                  {{
                    slotProps.option.option.producto_financiero
                      ? slotProps.option.option.producto_financiero.alias
                      : ""
                  }}
                </p>
                <p>
                  Capital autorizado:
                  {{
                    slotProps.option.option.capital_autorizado | currency | hp
                  }}
                </p>
                <p>
                  Capital disponible:
                  <span
                    :class="{
                      'has-text-danger':
                        slotProps.option.option.capital_disponible == 0
                    }"
                    class="font-bold"
                    >{{
                      slotProps.option.option.capital_disponible | currency | hp
                    }}</span
                  >
                </p>
              </div>
            </template>
          </ValidAutoComplete>
          <valid-currency-input
            :rules="`currency|max_value:${disposicion.capital}`"
            label="Valor del fondeo"
            :placeholder="'Introduzca un valor (max: ' + disposicion.capital + ')...'"
            v-model="form.valor_fondeo"
            :expanded="true"
          />
          <b-checkbox class="mt-4" size="is-medium" v-model="form.calzar"
            >Calzar</b-checkbox
          >
        </div>
      </section>
      <section class="modal-card-foot justify-end has-background-grey-lighter">
        <b-button
          type="is-primary"
          @click="validate().then(result => fondear(result))"
          >Continuar</b-button
        >
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { mapGetters } from "vuex";

export default {
  name: "FondearDisposicion",
  props: {
    disposicion: Object
  },
  components: {
    ValidationObserver,
    ValidSelect,
    ValidInput,
    ValidAutoComplete,
    ValidCurrencyInput
  },

  data() {
    return {
      form: {
        fuente_fondeo: null,
        identificador_fondeador: null,
        linea_id: null,
        calzar: null,
        destino_recursos: null,
        valor_fondeo: null,
      }
    };
  },
  methods: {
    getLineas() {
      this.$store.dispatch(
        "lineascredito/getLineasPasivasByPersona",
        this.form.fuente_fondeo
      );
    },
    /*
      @result (Boolean): Resultado de las validaciones
    */
    fondear(result) {
      if (!result) return;
      this.$store
        .dispatch("disposicion/asignarFuenteFondeo", {
          disposicion_id: this.disposicion.id,
          persona2_id: this.form.fuente_fondeo
        })
        .then(data => {
          if (data.notification) {
            this.notification(
              "warning",
              "Financiamiento rural",
              data.message,
              8000
            );
          }

          this.$router.push({
            name: "fondear_disposicion",
            query: this.form
          });
        });
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getFuentesFondeo"]),
    ...mapGetters("lineascredito", ["getLineasCliente"])
  },
  mounted() {
    if (this.getFuentesFondeo == null) {
      this.$store.dispatch("catalogos/getFuentesFondeo");
    }
    if (this.disposicion) {
      this.form.destino_recursos = this.disposicion.destino_recursos_id;
    }
  }
};
</script>

<style lang="scss"></style>
