var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.align},[_c('label',{staticClass:"font-bold has-text-primary",class:_vm.titleClass},[_vm._v(" Tabla de amortización ")]),_c('div',{staticClass:"flex justify-between columns"},[_c('tabs',{staticClass:"max-w-sm column is-4",attrs:{"tabs":_vm.tabs,"arrendamiento":_vm.arrendamiento,"pasivo":_vm.getDisposicionDetalle && _vm.getDisposicionDetalle.pasiva == 1},on:{"change":_vm.formatAmortizaciones},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),(_vm.activeTab == 1)?_c('div',{staticClass:"flex justify-around max-w-sm column is-2 btn-group pull-right"},[_c('b-button',{attrs:{"type":_vm.arrendamiento ? 'is-arrendamiento' : 'is-primary'},on:{"click":_vm.imprimirTabla}},[_c('i',{staticClass:"fa fa-print",attrs:{"aria-hidden":"true"}})]),_c('b-button',{attrs:{"type":_vm.tablaSinDetalles 
                ? _vm.arrendamiento 
                  ? 'is-arrendamiento'
                  : 'is-primary' 
                : 'is-ghost',"icon-right":_vm.tablaSinDetalles ? 'expand' : 'compress'},on:{"click":function($event){_vm.tablaSinDetalles = !_vm.tablaSinDetalles}}}),_c('b-button',{attrs:{"type":_vm.tablaSoloSaldos 
                ? _vm.arrendamiento 
                  ? 'is-arrendamiento'
                  : 'is-primary' 
                : 'is-ghost'},on:{"click":function($event){_vm.tablaSoloSaldos = !_vm.tablaSoloSaldos}}},[_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"aria-hidden":"true"}})])],1):_vm._e()],1),(_vm.activeTab == 1)?_c('div',[(!_vm.tablaSinDetalles)?_c('tabla-simulacion',{attrs:{"tablaAmortizacion":_vm.tablaAmortizacion,"tasaBase":_vm.tasaBase,"tableClass":_vm.tableClass,"sinIvaCapitalizado":_vm.sinIvaCapitalizado,"arrendamiento":_vm.arrendamiento,"soloSaldos":_vm.tablaSoloSaldos,"capital":_vm.data[0].capitalInsoluto}}):_vm._e(),(_vm.tablaSinDetalles)?_c('tabla-simulacion-sin-detalles',{attrs:{"tablaAmortizacion":_vm.tablaAmortizacionSinDetalles,"tableClass":_vm.tableClass,"arrendamiento":_vm.arrendamiento,"soloSaldos":_vm.tablaSoloSaldos,"capital":_vm.data[0].capitalInsoluto}}):_vm._e()],1):_vm._e(),(_vm.activeTab == 0)?_c('div',[_c('tabla-amortizaciones',{attrs:{"amortizaciones":_vm.getDisposicionDetalle &&
          _vm.getDisposicionDetalle.fechas_vencimiento_capital,"esUnaSolaAmortizacion":_vm.getDisposicionDetalle.tipo_pago.clave == 'vencimiento'}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }